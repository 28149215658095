﻿var jquery = require('jquery');


(function () {
    'use strinct';
    function Promotions(dataLayer) {
        this._dataLayer = dataLayer;
        this.itemList = [];
    }


    Promotions.prototype = {
        constructor: Promotions,
        click: function (oData, callbackFunction){
            try {
                oData = (typeof (oData) !== "object" ? {} : oData);
                if (Object.keys(oData).length > 0) {
                    this._dataLayer.push({
                        "event": "promotionClick",
                        "ecommerce": {
                            "promoClick": {
                                "promotions": [{
                                    "id": oData.id ? oData.id : oData.gtmid,
                                    "name": oData.name ? oData.name : oData.gtmname,
                                    "creative": oData.creative ? oData.creative : oData.gtmcreative,
                                    "position": oData.position ? oData.position : oData.gtmposition
                                }]
                            }
                        },
                        'eventCallback': function () { callbackFunction(oData); }
                    });
                }
            } catch (err) {
                callbackFunction(oData);
            }
            
        },
        setView: function (oDataList) {

            var oItem, oDataListItem;
            oDataList = (typeof (oDataList) !== "object" ? {} : oDataList);

            if (oDataList.length > 0) {
                for (var i = 0; i < oDataList.length; i++) {
                    oDataListItem = jquery(oDataList[i]).data();
                    oItem = { "id": oDataListItem.gtmid, "name": oDataListItem.gtmname, "creative": oDataListItem.gtmcreative, "position": oDataListItem.gtmposition };
                    this.itemList.push(oItem);
                }

                if (this.itemList.length > 0) {
                    this._dataLayer.push({
                        "event": "promotionView",
                        "ecommerce": {
                            "promoView":
                                {
                                    "promotions": this.itemList
                                }
                        }
                    });
                }
            } else {
                console.log("GTM promotions empty");
            }
        },
        sendPromotionsView: function (itemList) {

            if (itemList) {
                this._dataLayer.push({
                    "event": "promotionView",
                    "ecommerce": {
                        "promoView":
                            {
                                "promotions": itemList
                            }
                    }
                });
            }

        }
    };


    if (typeof module !== 'undefined' && module.exports) {
        module.exports = Promotions;
    }
    else {
        window.Menu = Promotions;
    }



})();