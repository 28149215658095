﻿/* globals Facebook, GoogleLoginInstance, google, analytics*/
require("../Global/facebook");
require("../Global/google-login");
var jquery = require("jquery");
var storageService = require( '../Global/storage-service').default;
var HabEvents = require("../Global/habitaclia-events");
var CookieService = require("../Global/cookie-service");

(function () {
    "use strict";

    var CredentialManagement = function () {
        this.__credentialManagementDTO = window.CredentialManagementDTO || { userIsLogged: true, isMobileApp: true };
        this.__credentialAvailable = !!window.PasswordCredential && !!navigator.credentials;
        this.__PASSWORD_LOGIN = 'password';
        this.__FACEBOOK_PROVIDER = 'https://www.facebook.com';
        this.__GOOGLE_PROVIDER = 'https://account.google.com';
        this.__REGISTER = 'register';
        this.__SIGNOUT = 'signout';
        this.__STORAGE_ITEM = 'credentials';
        this.__timeToTryLogin = 604800000;
        this.__$showModalLogin = jquery('#js-show-login');

        this.__storageService = new storageService();
        this.__hasLocalStorage = this.__storageService.checkLocalStorage();
        __registerSignOutEvent.call(this);
        if (typeof google !== "undefined" && !this.__credentialManagementDTO.isMobileApp && !this.__credentialManagementDTO.userIsLogged) {
            __initGoogleOneTap.call(this);
        }
    };

    var __initGoogleOneTap = function () {
        google.accounts.id.initialize({
            client_id: this.__credentialManagementDTO.oneTapId,
            callback: __handleCredentialResponse,
            state_cookie_domain: this.__credentialManagementDTO.domainName,
            auto_select: true
        });
        google.accounts.id.prompt((notification) => {
            if (notification.isNotDisplayed()) {
                __startCredentialManager.call(this);
            }
        });
    };

    var __startCredentialManager = function () {
        if (this.__hasLocalStorage && this.__credentialAvailable) {
            __registerSubscriberSuccessfulLog.call(this);
            if (__isTimeToTry.call(this)) {
                __getCredential.call(this);
            } else {
                __tryCookieSocial.call(this);
            }
        } else {
            __tryCookieSocial.call(this);
        }
    };

    var __handleCredentialResponse = function (CredentialResponse) {
        var googleUser = {
            getAuthResponse: () => ({ id_token: CredentialResponse.credential })
        };
        GoogleLoginInstance.logIn(googleUser);

    };

    var __isTimeToTry = function () {
        var resTime = this.__timeToTryLogin;
        if (this.__storageService.itemExist(this.__STORAGE_ITEM)) {
            var data = this.__storageService.getItem(this.__STORAGE_ITEM);
            var actualDate = new Date();
            var actualTime = actualDate.getTime();
            resTime = actualTime - data.lastdate;
        }
        return resTime >= this.__timeToTryLogin;
    };

    var __setTriedItem = function () {
        var data = new Date();
        this.__storageService.setItem(this.__STORAGE_ITEM, { lastdate: data.getTime()});
    };

    var __registerSubscriberSuccessfulLog = function () {
        jquery.subscribe(HabEvents.EventsEnum.ClassicAspLogged, (e, data, response, provider) => {
            if (response.id) {
                const cred = new window.FederatedCredential({
                    id: response.id,
                    name: response.email,
                    provider: provider
                });

                navigator.credentials.store(cred);
            }
        });
    };

    var __getCredential = function () {
        window.navigator.credentials.get({
            password: true,
            unmediated: true,
            federated: {
                providers: [
                  this.__GOOGLE_PROVIDER,
                  this.__FACEBOOK_PROVIDER
                ]
            }
        }).then((cred) => {
            if (cred) {
                switch (cred.type) {
                    case 'password':
                        if (cred.password !== undefined) {
                            __fetch.call(this, this.__PASSWORD_LOGIN, cred.id, cred.password);
                        }
                        break;
                    case 'federated':
                        if (cred.provider === this.__FACEBOOK_PROVIDER) {
                            Facebook.TryLogin();
                        } else if (cred.provider === this.__GOOGLE_PROVIDER) {
                            GoogleLoginInstance.signIn();
                        }
                        break;
                }
            } else {
                __tryCookieSocial.call(this);
            }
                /*
                __trySmartLock.call(this);
            } */
            __setTriedItem.call(this);
        });
    };

    var __tryCookieSocial = function () {
        const googleAutologin = CookieService.getCookie('habGoogleAutologin');
        const facebookAutologin = CookieService.getCookie('habfbautologin');
        if (facebookAutologin === 'true') {
            __tryFacebookLogin.call(this);
        } else if (googleAutologin === 'true') {
            GoogleLoginInstance.autoSignIn();
        } else {
            jquery.publish(HabEvents.EventsEnum.OpenSocialLoginModal);
        }
    };

    var __tryFacebookLogin = function () {
        if (window.FB) {
            Facebook.TryLogin();
        } else {
            jquery.subscribe(HabEvents.EventsEnum.FacebookLoaded, () => {
                Facebook.TryLogin();
            });
        }
    };

    var __registerSignOutEvent = function () {
        jquery.subscribe(HabEvents.EventsEnum.LogOut, (e, currentTarget) => {
            const cookieEmail = CookieService.getCookie('logintype');
            Facebook.SignOut();
            analytics.reset();
            window.sessionStorage && window.sessionStorage.removeItem('user');
            window.sessionStorage && window.sessionStorage.removeItem('stateFields');
            if (this.__credentialAvailable && navigator.credentials.preventSilentAccess) {
                navigator.credentials.preventSilentAccess();
            }
            if (cookieEmail && cookieEmail === 'google') {
                GoogleLoginInstance.signOut(currentTarget.href);
            } else {
                window.location.href = currentTarget.href;
            }
        });
    };
    
    var __fetch = function (provider, user, pass) {
        var self = this;
        let url = '';
        switch (provider) {
            case this.__PASSWORD_LOGIN:
                url = '/hab_usuarios/ajax/login.asp';
                break;
        }
        var request = jquery.post(url, 'ax=-1&action=login&idioma='+self.__credentialManagementDTO.idioma+'&usuario='+user+'&password='+pass+'&cookies=yes');

        request.done(function (data) {
            var oData = JSON.parse(data);
            if(oData[0].status.code===200){
                jquery.publish(HabEvents.EventsEnum.LoginAjaxOk);
                if (self.__credentialManagementDTO.isMobile) {
                    window.location.reload();
                }
            }else{
                self.__$showModalLogin.click();
            }
        });
    };

    CredentialManagement.prototype = {
        constructor: CredentialManagement
    };

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = new CredentialManagement();
    }
    else {
        window.CredentialManagement = new CredentialManagement();
    }

}());