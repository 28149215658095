﻿/* globals LayoutDTO */
require('../../Common/Layout/layout');
require("../Menu/Menu");
require("../../Global/facebook");
require("../Solicitudes/SolicitudesMenu");
require("../../Global/shared-session.js");
require("../../Common/Trackings/trackings");
require('../../Global/credential-management');
var HabEvents = require("../../Global/habitaclia-events");
var jquery = require("jquery");
var cookieService = require("../../Global/cookie-service");


(function () {
    "use strict";

    var Layout = function (layoutDTO) {
        this.__layoutDTO = layoutDTO;
        /*jshint unused: false*/
        __registerEvents.call(this);

        if (this.__layoutDTO.abrirMenu === 'True')
        {
            jquery.publish(HabEvents.EventsEnum.AbrirMenu);
        }
        //__callRememberMeAsp.call(this);

        if (this.__layoutDTO.doLoginUserGuest === 'True') {
            __autoLoginUserGuest.call(this);
        }
        this._delayAds = window.location.href.indexOf('mode=ludicrous') > 0 ? 5000 : 0;
        __initGPT.call(this);
        
    };

    var __registerEvents = function () {
        var self = this;
        jquery(document).ready(
            function () {

                if (__existsValorarApp.call() && !__isValorarAppSetted.call()) {
                    jquery(".puntua-app-home, .puntua-app-menu").show();
                }

                if (__existsValorarAppListadoFicha && __isValorarAppSetted.call()) {
                	jquery("#puntuarAppView").addClass('ocultar');
                }

                jquery("#cargando").fadeOut(400, function () {
                    jquery("#cargando").css('animation-iteration-count', '0').fadeIn(400);
                });

                jquery("#puntuarapp-home-action, #puntuarapp-menu-action, #botonValorar").click(function (e) {
                    e.preventDefault();
                    var href = jquery(this).attr("href");
                    __setCookieMostrarValorarAppVisited.call();
                    if (href.length > 0) {
                        document.location = href;
                    }
                });

                jquery("#enviarComentario").click(function () {
                	__setCookieMostrarValorarAppVisited.call();
                });

                jquery("#botonValorarSi").click(function (e) {
                    e.preventDefault();
                    var puntuarContainer = jquery('#puntuarAppView');
                    var origenPosicion = puntuarContainer.attr('data-origen') + '-' + puntuarContainer.attr('data-posicion');
                    jquery.publish(HabEvents.EventsEnum.ClickValorarApp, ['Si', origenPosicion]);
                });
                jquery("#botonValorarNo").click(function (e) {
                    e.preventDefault();
                    var puntuarContainer = jquery('#puntuarAppView');
                    var origenPosicion = puntuarContainer.attr('data-origen') + '-' + puntuarContainer.attr('data-posicion');
                    jquery.publish(HabEvents.EventsEnum.ClickValorarApp, ['No', origenPosicion]);
                });

                jquery('body').on('mousedown', function (e) {
                    e = e || window.event;
                    var target = e.target || e.srcElement;
                    var $target = jquery(target);
                    if ($target.hasClass('click')) {
                        __callbackClickEffect.call(self, e);
                    }
                });
                
                jquery('#js-head-publish, .js-publish-ad').click(function (e) {
                    e.preventDefault();
                    var url = jquery(this).attr('href');
                    jquery.publish(HabEvents.EventsEnum.NavegacionFicha, ['Navegacion', 'habitaclia', 'publicaranuncio']);
                    jquery.publish(HabEvents.EventsEnum.PublishAd, url);
                });
                
                window.onbeforeunload = function (e) {
                    if (e) {
                        jquery("#cargando").css('animation-iteration-count', 'infinite');
                    }
                    return;
                };

            });
    };

    //var __callRememberMeAsp = function () {
    //    var existLoginFromRememberMeCookie = cookieService.hasCookie('lfrm');

    //    /*En la ficha tenemos incrustado solicitud.asp que ya hace el login con RememberMe y no hace falta hacer la llamada Ajax*/
    //    if (existLoginFromRememberMeCookie && this.__layoutDTO.esNavegacionDeFicha === 'False') {
    //        var req = jquery.ajax({
    //            url: this.__layoutDTO.urlHabitacliaCheckRememberMe,
    //            type: 'GET',
    //            dataType: 'json',
    //            cache: false
    //        });

    //        req.done(function (data) {
    //            if (data) {
    //                if (data.status) {
    //                    if (data.status.code === 200) {
    //                        cookieService.removeCookie('lfrm');
    //                    }
    //                }
    //            }

    //        });

    //        //req.fail(function (request, type) {});
    //    }
    //};

    var __existsValorarApp = function () {
        return jquery(".puntua-app-home, .puntua-app-menu").length > 0;
    };

    var __existsValorarAppListadoFicha = function () {
    	return jquery("#puntuarAppView").length > 0;
    };

    var __isValorarAppSetted = function () {
        if (cookieService.hasCookie('valorarhabitaclia')) {
            return true;
        }
        return false;
    };

    var __setCookieMostrarValorarAppVisited = function () {
        var domainName = __getDomainName.call();
        cookieService.setCookie('valorarhabitaclia', 'True', Infinity, '/', domainName);
    };

    var __getDomainName = function () {
        var host = window.location.hostname;
        host = host.replace("www.", "");
        host = host.replace("catala.", "");
        host = host.replace("english.", "");
        host = host.replace("francaise.", "");
        host = host.replace("deutsch.", "");
        host = host.replace("italiano.", "");
        host = host.replace("m.", "");
        host = host.replace("ipad.", "");
        host = host.replace("profesional.", "");
        return host;
    };

    var __callbackClickEffect = function (e) {
        var target,
            x,
            y,
            effect;

        e = e || window.event;

        target = e.target || e.srcElement,
            x = e.offsetX || e.layerX,
            y = e.offsetY || e.layerY;

        effect = document.createElement('div');
        effect.className = 'effect';
        effect.style.top = y + 'px';
        effect.style.left = x + 'px';
        target.appendChild(effect);
        setTimeout(function () {
            if (jquery.contains(target, effect)) {
                target.removeChild(effect);
            }
        }, 1100);
    };


    var __autoLoginUserGuest = function () {

        jquery.ajax({
            url: this.__layoutDTO.urlHabitacliaLoginUserGuest,
            type: 'GET',
            data: { token: this.__layoutDTO.tokenUserGuest },
            dataType: 'json',
            cache: false
        });


    };

    var __initGPT = function () {
        setTimeout(() => {
            var gpt = document.createElement('script');
            gpt.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
            document.head.appendChild(gpt);
            window.googletag = window.googletag || { cmd: [] };
            window.googletag.cmd.push(function () {
                window.googletag.defineSlot('/5180329/generico_bottom_m_300x250', [[300, 250], [320, 100], 'fluid', [300, 100]], 'div-gpt-ad-1565176942780-0').addService(window.googletag.pubads());
                window.googletag.pubads().enableSingleRequest();
                window.googletag.pubads().collapseEmptyDivs();
                window.googletag.enableServices();
            });
            window.googletag.cmd.push(function () { window.googletag.display('div-gpt-ad-1565176942780-0'); });
        }, this._delayAds);
    };

    Layout.prototype = {
        constructor: Layout
    };

    if (typeof module !== 'undefined' && module.exports) {
        /*jshint undef: false*/
        module.exports = new Layout(LayoutDTO);
    }
    else {
        window.Layout = Layout;
    }
}());

