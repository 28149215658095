﻿/* globals SharedSessionDTO*/
var jquery = require("jquery");

(function () {
    "use strict";

    var __sharedSessionDTO;
    var SharedSession = function (sharedSessionDTO) {
        __sharedSessionDTO = sharedSessionDTO;

        __registerEvents.call(this);

        if (__sharedSessionDTO.isUserLogged === "True") {
            __registerEventsLogged.call();
        }
    };

    var __registerEventsLogged = function () {
        setTimeout(function () {
            jquery.ajax({
                type: "POST",
                url: __sharedSessionDTO.urlMarkSharedSessionAsActive,
                dataType: "json"
            });
        }, parseInt(__sharedSessionDTO.refreshTime));
    };

    var __registerEvents = function () {
        var aspData = {
            emailUsuarioDotNet: __sharedSessionDTO.userMail,
            claveAleatoriaUsuarioDotNet: __sharedSessionDTO.userClaveAleatoria
        };
        setTimeout(function () {
            jquery.ajax({
                type: "POST",
                url: __sharedSessionDTO.urlHabitacliaMantenerSessionAspViva,
                data: aspData,
                error: function(jqXHR, textStatus)
                {
                    console.log(jqXHR);
                    console.log(textStatus);
                }
            });
        }, parseInt(__sharedSessionDTO.refreshTime));

        setInterval(function () {
            jquery.ajax({
                type: "POST",
                url: __sharedSessionDTO.urlHabitacliaMantenerSessionAspViva,
                dataType: "json"
            });
        }, parseInt(__sharedSessionDTO.refreshTimeInterval));
    };

    SharedSession.prototype = {
        constructor: SharedSession
    };

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = new SharedSession(SharedSessionDTO);
    }
    else {
        window.SharedSession = new SharedSession(SharedSessionDTO);
    }
}());