﻿/* globals gapi, google, googleLoginDTO */
var jquery = require("jquery");
var HabEvents = require("../Global/habitaclia-events");
var cookieService = require('../Global/cookie-service');
var googleType = 'google';

export default class GoogleLogin {

    constructor(googleLoginDTO)
    {
        this.googleLoginDTO = googleLoginDTO;
        this.includeGoogleScript();
        this.registerEvents();
        this.loginTypeCookieName = 'logintype';
    }

    includeGoogleScript()
    {
        const fjs = document.getElementsByTagName('script')[0];
        if (document.getElementById('google-signin-jssdk')) return;
        const js = document.createElement('script'); js.id = 'google-signin-jssdk';
        js.src = "//apis.google.com/js/platform.js";
        js.async = true;
        js.defer = true;
        fjs.parentNode.insertBefore(js, fjs);
    }

    registerEvents()
    {
        jquery.subscribe(HabEvents.EventsEnum.OpenLoginModal, () => {
            this.renderButton();
        });
    }

    logIn(googleUser)
    {
        const id_token = googleUser.getAuthResponse().id_token;
        const data = {token: id_token, hasLegalAgreement:false};
        fetch(this.googleLoginDTO.urlLogin, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json,charset=UTF-8'
              },
            body: JSON.stringify(data),
        }).then(res => {
            if (res.status === 200) {
                this.setLoginTypeCookie();
                return res.json();
            } else if (res.status === 206) {
                if (this.googleLoginDTO.isMobile) {
                    document.location.href = '/legal/' + googleType;
                    jquery.publish(HabEvents.EventsEnum.ShowMessageLegalLoginMobile, [googleType, id_token]);
                } else {
                    jquery.publish(HabEvents.EventsEnum.ShowModalLegalLogin, [googleType,id_token]);
                }
                throw new Error(res.status);
            } else {
               this.showUIError(res.message);
               throw new Error(res.status);
            }
        })
        .then(response => {
            const {domain, expires, name, path, token} = response;
            cookieService.setCookie(name, token, expires, path, domain);
            jquery.publish(HabEvents.EventsEnum.ClassicAspLogged, [data, response, 'https://account.google.com']);
            jquery.publish(HabEvents.EventsEnum.LoginAjaxOk);
            jquery.publish(HabEvents.EventsEnum.CloseWindowModal);
            this.removeUIError();
        })
        .catch(error => console.error('Error:', error));
    }

    showUIError(message)
    {
        let googleContainer = document.querySelector('.g-login-container');
        this.removeUIError();
        googleContainer.insertAdjacentHTML('beforeend',`<div class="error-texto">${message}</div>`);
        this.signOut();
    }

    removeUIError()
    {
        const uiError = document.querySelector('.g-login-container .error-texto');
        if(uiError){
            uiError.remove();
        }
    }

    setLoginTypeCookie() 
    {
        const isLoginType = cookieService.getCookie(this.loginTypeCookieName);
        if(!isLoginType) {
            cookieService.setCookie(this.loginTypeCookieName, googleType);
        }
    }

    onFailure()
    {
        console.log('error!');
    }

    signOut(url)
    {
        if(gapi.auth2) {
            gapi.auth2.getAuthInstance().signOut();
            if(url) {window.location.href = url;}
        }else{
            gapi.load('auth2', () => {
                gapi.auth2.init().then(()=>{
                    gapi.auth2.getAuthInstance().signOut();
                    if(url) {window.location.href = url;}
                });
            });
        }
        if (google) {
            google.accounts.id.disableAutoSelect();
        }
    }

    signIn()
    {
        if(window.gapi && gapi.auth2) {
            gapi.auth2.signIn();
        }else{
            window.gapi_onload = () => { 
                gapi.load('auth2', () => {
                    gapi.auth2.init().then(()=>{
                        const authInstance = gapi.auth2.getAuthInstance();
                        authInstance.signIn().then(googleUser=>{
                            this.logIn(googleUser);
                        });
                    });
                });
            };
        }
    }

    autoSignIn()
    {
        window.gapi_onload = () => { 
            gapi.load('auth2', () => {
                gapi.auth2.init().then(()=>{
                    const authInstance = gapi.auth2.getAuthInstance();
                    if(authInstance.isSignedIn.get()){
                        const googleUser = authInstance.currentUser.get();
                        this.logIn(googleUser);
                    }
                });
            });
        };
    }


    renderButton(id)
    {
        id = typeof id !== 'undefined' ? id : 'google-signin';
        gapi.signin2.render(id, {
            'scope': 'profile email',
            'width': 256,
            'height': 40,
            'longtitle': true,
            'onsuccess': (googleUser)=>this.logIn(googleUser),
            'onfailure': this.onFailure
        });
    }
}

/* jshint unused: false */
window.GoogleLoginInstance = new GoogleLogin(googleLoginDTO);
