﻿export default class StorageService {

    constructor(storage = !!window.localStorage ? window.localStorage : undefined)
    {
        this.storage = storage;
        if(!this.checkLocalStorage()){
            this.getItem = ()=>false;
            this.setItem = ()=>{};
            this.removeItem = ()=>{};
        }
    }

    checkLocalStorage()
    {
        try {
            if (typeof (Storage) !== undefined && !!this.storage) {
                var testKey = 'test';
                this.storage.setItem(testKey, '1');
                this.storage.removeItem(testKey);
                return true;
            } return false;
        } catch (error) {
            return false;
        }
    }

    getItem(name)
    {
        const rawItem = this.getRawItem(name);
        return rawItem ? JSON.parse(rawItem) : false;
    }

    getRawItem(name)
    {
        const rawItem = this.storage.getItem(name);
        return rawItem ? rawItem : false;
    }

    setItem(name, data)
    {
        this.storage.setItem(name, JSON.stringify(data));
    }

    removeItem(name)
    {
        this.storage.removeItem(name);
    }

    itemExist(name)
    {
        return !!this.getRawItem(name);
    }

   areEqual(name, data){
       return this.getRawItem(name) === JSON.stringify(data) ? true : false;
    }
}
