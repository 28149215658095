﻿/* globals FB, facebookDTO */
var jquery = require("jquery");
var HabEvents = require("../Global/habitaclia-events");
var cookieService = require("../Global/cookie-service");

(function () {
    "use strict";

    var useFacebookConnect;
    var __urlJustLogin, __isMobile;
    var facebookType = 'facebook';
    var loginTypeCookieName = 'logintype';
   
    var Facebook = function (facebookDTO) {
        __urlJustLogin = facebookDTO.urlLogin;
        __isMobile = facebookDTO.isMobile;
        useFacebookConnect = cookieService.getCookie('habfbautologin');

        console.log('facebook connect activo');
        if (window.location.href.indexOf('mode=ludicrous') === -1) {
            __includeFacebookScript.call(this, __registerEvents);
            console.log('intentando conectar con facebook...');
        }
        
    };

    var __registerEvents = function () {
        if (!!FB) {
            FB.Event.subscribe('edge.create',
                function (href) {
                    jquery.publish(HabEvents.EventsEnum.SocialNetworkCallback, ['Facebook', 'Like', href]);
                }
            );
            jquery.subscribe(HabEvents.EventsEnum.OpenLoginModal, () => {
                __initFB.call(this);
            });
        }
        
    };

    var __includeFacebookScript = function (callback) {
        var js, fjs = document.getElementsByTagName('script')[0];
        if (document.getElementById('facebook-jssdk')) return;
        js = document.createElement('script'); js.id = 'facebook-jssdk';
        js.src = "//connect.facebook.net/es_ES/sdk.js";
        js.async = true;
        js.defer = true;
        if (callback) {
            js.addEventListener('load', function (e) {
                callback(null, e);
                jquery.publish(HabEvents.EventsEnum.FacebookLoaded);
            }, false);
        }
        fjs.parentNode.insertBefore(js, fjs);
    };

    var __fbAsyncInit = function () {
        console.log('conectado con facebook.');
        var self = this;
        __initFB.call(this);

        FB.getLoginStatus(function (response) {
            console.log('validando logging con facebook...');
            if (response.status === 'connected') {
                console.log('usuario logado en facebook.');
                var accessToken = response.authResponse.accessToken;
                __getDataFromFacebook.call(self, accessToken, 'auto');
            }
            else {
                console.log('usuario no logado en facebook.');
            }
        });
    };

    var __initFB = function () {
        FB.init({
            appId: '367507690061194',
            cookie: true,
            xfbml: true,
            version: 'v10.0'
        });
    };

    var __getDataFromFacebook = function (accessToken, origen, url) {
        var self = this;
        FB.api('/me?fields=id,name,email,first_name,last_name', function (response) {
            console.log("consultando datos necesarios...");
            //console.log(response);
            jquery.publish(HabEvents.EventsEnum.FacebookLogged);
            __login.call(self, accessToken, origen, response, url);
        });
    };


    var __loginOnFacebook = function (url) {
        url = typeof url !== 'undefined' ? url : __urlJustLogin;
        console.log('conectado con facebook.');
        var self = this;
        __initFB.call(this);

        FB.login(function (response) {
            console.log('validando logging con facebook...');
            if (response.status === 'connected') {
                console.log('usuario logado en facebook.');
                var accessToken = response.authResponse.accessToken;
                __getDataFromFacebook.call(self, accessToken, 'manual', url);
            }
            else {
                console.log('usuario no logado en facebook.');
            }
        }, {scope: 'public_profile,email'});
    };

    var __login = function (accessToken, origen, response, url) {
        var self = this;
		if (url) {
			var req = jquery.ajax({
				url: url,
				data: { "token": accessToken, "hasLegalAgreement":false },
				type: 'POST',
				cache: false
			});

			req.done(function (response) {
			    if (response.status === 200) {
                    __setLoginTypeCookie.call();
                    const {domain, expires, name, path, token} = response;
                    cookieService.setCookie(name, token, expires, path, domain);
			        jquery.publish(HabEvents.EventsEnum.ClassicAspLogged, [response, 'https://www.facebook.com']);
			        jquery.publish(HabEvents.EventsEnum.LoginAjaxOk);
			        jquery.publish(HabEvents.EventsEnum.CloseWindowModal);
			        __removeUIError.call();
                } else if (response.status === 206) {
                    if (__isMobile) {
                        document.location.href = '/legal/' + facebookType;
                        jquery.publish(HabEvents.EventsEnum.ShowMessageLegalLoginMobile, [facebookType, accessToken]);
                    } else {
			            jquery.publish(HabEvents.EventsEnum.ShowModalLegalLogin, [facebookType, accessToken]);
                    }
			    } else {
			        __showUIError.call(self, response.message);
			    }
			});

			req.fail(function (request, type) {
				console.log(request);
				console.log(type);
			});
		}
	};

    var __setLoginTypeCookie = function() 
    {
        const isLoginType = cookieService.getCookie(loginTypeCookieName);
        if(!isLoginType) {
            cookieService.setCookie(loginTypeCookieName, facebookType);
        }
    };

    var __showUIError = function (message) {
        let facebookContainer = document.querySelector('.dfacebook');
        __removeUIError.call();
        facebookContainer.insertAdjacentHTML('beforeend', `<div class="error-texto">${message}</div>`);
        __signOut.call(this);
    };

    var __removeUIError = function () {
        const uiError = document.querySelector('.dfacebook .error-texto');
        if (uiError) {
            uiError.remove();
        }
    };

    var __tryLogin = function () {
        __initFB.call(this);
        FB.getLoginStatus((response) => {
            if (response.status === 'connected') {
                var accessToken = response.authResponse.accessToken;
                __login.call(this, accessToken, 'manual', response, __urlJustLogin);
            } 
        });
    };

    var __signOut = function () {
        __initFB.call(this);
        if (window.FB) {
           FB.logout();
        }
    };

    var __loginFromAlert = function (url) {
        jquery.publish(HabEvents.EventsEnum.ClickLoginFacebookFaldon);
        __loginOnFacebook.call(this, url);
    };

    Facebook.prototype = {
        constructor: Facebook,
        LoginOnFacebook: __loginOnFacebook,
        LoginFromAlert: __loginFromAlert,
        TryLogin: __tryLogin,
        SignOut: __signOut,
        InitFB: __initFB
    };

    window.Facebook = new Facebook(facebookDTO);

    if ((useFacebookConnect === null || useFacebookConnect.toLowerCase() === "true")
               && facebookDTO.isUserLogged.toLowerCase() === 'false') {
        window.fbAsyncInit = __fbAsyncInit;
    }
    
}());