﻿(function () {
    "use strict";

    var Comscore = function () {
        __IncludeComScoreTag();
    };

    var __IncludeComScoreTag = function () {
        /* jshint ignore:start */
        window._comscore = window._comscore || [];
        _comscore.push({ c1: "2", c2: "16040456" });

        var s = document.createElement("script"),
        el = document.getElementsByTagName("script")[0];
        s.async = true;
        s.src = (document.location.protocol == "https:" ? "https://sb" : "http://b") + ".scorecardresearch.com/beacon.js";
        el.parentNode.insertBefore(s, el);
        /* jshint ignore:end */
    };

    Comscore.prototype = {
        constructor: Comscore,
    };

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = new Comscore();
    }
    else {
        window.Comscore = new Comscore();
    }

}());