﻿/*globals menuDTO*/
var jquery = require('jquery');
var WindowModal = require('../../Global/window-modal');
var HabEvents = require("../../Global/habitaclia-events");
var storageService = require("../../Global/storage-service").default;

(function () {
    "use strict";

    var __urlMenuPartialView;
    var menuAnterior;
    var __Menu;
    var __$elementoMenu;
    var __$iconClose;
    var __storageService;

    var Menu = function (menuDTO) {
        __Menu = menuDTO;
        __urlMenuPartialView = menuDTO.urlMenuPartialView;
        __$elementoMenu = jquery("#menumobile");
        __$iconClose = jquery("#icon_close");

        menuAnterior = undefined;
        __registerGlobalEvents();
        __registerMenuEvents();
        __subscribeToEvents();
        __storageService = new storageService();
    };

    var __registerGlobalEvents = function () {
        var self = this;
        jquery("#menu").click(function () {
            __showMenu.call(self);
        });

        jquery("#blokcontent").click(function () {
            __clickCallbackClose();
        });

        jquery(".submenu").click(function () {
            __ToogleMenu(this);
        });
      
        jquery("#desactivar-anuncio").click(function () {
            __clickCallbackClose();
            var modal = new WindowModal();
            modal.OnReady(__bindEventsModalSearch);
            modal.OnDestroy(__unbindEventsModalSearch);
            modal.CreateWindowModal({
                idSelectorString: "modal-desactivar-anuncio",
                dataTitle: '<div class="titleModal">¿Quieres desactivar tu anuncio?</div><a href="#" class="closeModal" role="button" tabindex="8" title="Cerrar"></a>',
                dataBody: jquery("#html-desactivar-anuncio").html(),
                dataFooter: '',
                modalWidth: "200px"
            });
            __registerModalEvents();
        });
    };

    var __showMenu = function () {
        jquery("#blokcontent").fadeIn(300);
        __$elementoMenu.addClass('openMenu');
        //__$elementoMenu.css('right', '0px');
        __$iconClose.show();
    };

    var __registerModalEvents = function () {

        jquery(".habDialog-close").on('click', function (e) {
            e.preventDefault();
            jquery.publish(HabEvents.EventsEnum.CloseWindowModal);
        });

        jquery("#idDialogDisableAd-Desactivar").on('click', function () {
            jquery.publish(HabEvents.EventsEnum.DialogSubmitDisableAd);
        });
    };

    var __bindEventsModalSearch = function () {
        jquery(".closeModal").on("click", function (e) {
            e.preventDefault();
            jquery.publish(HabEvents.EventsEnum.CloseWindowModal);
        });
    };

    var __unbindEventsModalSearch = function () {
        jquery(".closeModal").off("click");
    };

    var __registerMenuEvents = function () {
        jquery("#icon_close").click(function () {
            __clickCallbackClose();
        });
        __$elementoMenu = jquery("#menumobile");
        __$iconClose = jquery("#icon_close");

        jquery('.logoff').click(function (e) {
            e.preventDefault();
            jquery.publish(HabEvents.EventsEnum.LogOut, [e.target]);
            __storageService.checkLocalStorage() && __storageService.removeItem('keepForm');
        });
    };

    var __subscribeToEvents = function () {

        jquery.subscribe(HabEvents.EventsEnum.ClassicAspLogged, __reloadMenu);
        //jquery.subscribe(HabEvents.EventsEnum.FavoritoAnadido, __reloadMenuFavoritosAdd);
        //jquery.subscribe(HabEvents.EventsEnum.FavoritoEliminado, __reloadMenuFavoritosDel);
        //jquery.subscribe(HabEvents.EventsEnum.AddAlertaListado, __reloadMenuAlertas);
        jquery.subscribe(HabEvents.EventsEnum.AbrirMenu, __showMenu);

        jquery.subscribe(HabEvents.EventsEnum.DialogSubmitDisableAd, function (e) {
            e.preventDefault();
            jquery.post("/hab_usuarios/ajax/inmueble.asp", { action: "disable", urid: __Menu.userId, val: -1 }, function (data) {
                if (data.status.code === 1) {
                    document.location = "/hab_usuarios/views/inmueble/inmuebleContent.asp";
                }
                else if (data.status.code === -1) {
                    document.location = "/login";
                }
                else {
                    document.location = "/hab_usuarios/home.asp";
                }
            }, "json");
            jquery.publish(HabEvents.EventsEnum.CloseWindowModal);
        });

    };

    var __clickCallbackClose = function () {
        jquery("#blokcontent").fadeOut(100);
        __$elementoMenu.removeClass('openMenu');
        //__$elementoMenu.css('right', '-350px');
        __$iconClose.hide();
    };

    var __ToogleMenu = function (self) {
        var actualId = self.id;
        /* jshint eqeqeq: false*/
        if (menuAnterior !== undefined && menuAnterior != actualId) {
            jquery('#' + menuAnterior).children('.children').slideUp();
            jquery('#' + menuAnterior + 'dropdown').removeClass('dropup-menu');
        }
        jquery(self).children('.children').slideToggle();
        jquery('#' + actualId + 'dropdown').toggleClass('dropup-menu');
        menuAnterior = actualId;
    };

    var __reloadMenu = function () {
        var req = jquery.ajax({
            url: __urlMenuPartialView,
            data: '',
            type: 'POST',
            cache: false
        });

        req.done(function (data) {
            jquery('#menu_ajax_container').empty();
            jquery('#menu_ajax_container').html(data);
            __registerMenuEvents();
            console.log('reload menú.');
        });

        req.fail(function (request, type) {
            console.log(request);
            console.log(type);
        });
    };
 
    Menu.prototype = {
        constructor: Menu,
        registerGlobalEvents : function () {
            __registerGlobalEvents.call(this);
        },
        registerMenuEvents : function () {
            __registerMenuEvents.call(this);
        }
    };

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = new Menu(menuDTO);
    }
    else {
        window.Menu = new Menu(menuDTO);
    }

}());