﻿/* globals SolicitudesMenuDTO */
var jquery = require('jquery');

(function () {
    "use strict";
    var __SolicitudesMenuDTO;

    var SolicitudesMenu = function (solicitudesMenu) {
        __SolicitudesMenuDTO = solicitudesMenu;
        if (__SolicitudesMenuDTO.userlogado === "True")
        {
            setTimeout(__SolicitudesRefresh, parseInt(__SolicitudesMenuDTO.refreshTime));
        }
    };

    var __SolicitudesRefresh = function(){
        jquery.ajax({ 
            method: "POST",
            url: __SolicitudesMenuDTO.urlSolicitudesSinLeerCount,
            cache: false,
        }).done(function(msg) {
            if (msg !== "")
            {
                jquery("#solicitudescount").text(msg);
                jquery(".notificaciones-numeral").text(msg);
                jquery(".notificaciones-numeral-naranja").text(msg);
                jquery("#nofifnoleidas").text(msg);
                if (msg !== 0)
                {
                    jquery("#idsolicidudes").removeClass("ocultar");
                }
                else if (msg === 0)
                {
                    jquery("#idsolicidudes").addClass("ocultar");
                }
            }
        }).fail(function (request, type) {
            console.log(type);
        });
    };

    SolicitudesMenu.prototype = {
        constructor: SolicitudesMenu
    };

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = new SolicitudesMenu(SolicitudesMenuDTO);
    }
    else {
        window.SolicitudesMenu = new SolicitudesMenu(SolicitudesMenuDTO);
    }
}());