﻿/* globals dataLayer */
var jquery = require('jquery');
var Product = require("./gtm-ecommerce/Products");
var ProductDetail = require("./gtm-ecommerce/ProductDetail");
var AddToCart = require("./gtm-ecommerce/AddToCart");
var CheckOut = require("./gtm-ecommerce/CheckOut");
var Purchase = require("./gtm-ecommerce/Purchase");
var Promotions = require('./gtm-ecommerce/Promotions');
var HabEvents = require("../../Global/habitaclia-events");


(function () {
    "use strict";

    var GoogleTagManager = function () {
        __Initialize();
    };

    var __Initialize = function () {

        var __oProduct = new Product(dataLayer);
        __oProduct.setView(jquery(".gtmlistproductview"));
        
        jquery(".gtmproductclick").on("click", function () {
            __oProduct.click(jquery(this).data(), function (oData) {
                var url;
                url = oData.gtmurl;
                console.log("GTM product click");
            });
        });
        
        var __oProductDetail = new ProductDetail(dataLayer);
        __oProductDetail.setView(jquery(".gtmproductdetail"));

        jquery.subscribe(HabEvents.EventsEnum.FavoritoAnadido, function (e, selectorGtm) {
            var __oAddToCart = new AddToCart(dataLayer);
            __oAddToCart.add(jquery(selectorGtm));
        });

        jquery.subscribe(HabEvents.EventsEnum.FocusToSolicitud, function (e, selectorGtm) {
            var __oCheckOut = new CheckOut(dataLayer);
            __oCheckOut.setFocus(jquery(selectorGtm));
        });

        jquery.subscribe(HabEvents.EventsEnum.ConfirmacionSolicitudEnviada, function () {
            var _Purchase = new Purchase(dataLayer);
            _Purchase.setView(jquery(".gtmproductpurchase"));
        });
        jquery.subscribe(HabEvents.GTMEcommerceEventsEnum.ClicGtmItemUrl, function (e, oDataClick) {
            //oGtmProductClick(oDataClick);
            var sURL = oDataClick.url;
            var sTarget = oDataClick.target;
            sURL = sURL + (sURL.indexOf("?") >= 0 ? "&gtmcoupon=" : "?gtmcoupon=") + oDataClick.gtmlist;
            window.open(sURL, sTarget);
        });


        var promotions = new Promotions(dataLayer);
        promotions.setView(jquery('.gtmpromotionview'));

        jquery('.gtmclickpromo').click(function () {
            promotions.click(jquery(this).data());
        });

    };


    GoogleTagManager.prototype = {
        constructor: GoogleTagManager
    };

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = new GoogleTagManager();
    }
    else {
        window.GoogleTagManager = new GoogleTagManager();
    }

}());