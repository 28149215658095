﻿/* globals  dataLayer, GoogleTagManagerEventsDTO */
var jquery = require('jquery');
var HabEvents = require("../../Global/habitaclia-events");

(function () {
    "use strict";

    var GTMTrackEvents = function () {
        __RegisterGTMTrackEvents();
    };

    var __RegisterGTMTrackEvents = function () {

        jquery.subscribe(HabEvents.EventsEnum.AddAlertaListado, function (e, numeroAlertas, sCategory, sAction, sLabel, oDataGtm) {
            /*jshint unused:false*/
            __PushTrackEventWhitCustomDimensions(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickCompartirFichaItem, function (e, sCategory, sAction, sLabel, oDataGtm) {
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickPrimeraFotoFicha, function (e, oDataGtm) {
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickNavegacionFoto, function (e, oDataGtm) {
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickCerrarFoto, function (e, oDataGtm) {
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.IrAFormularioContacto, function () {
            dataLayer.push(window.GTMEvents.gtmIrAFormularioContacto);
        });

        jquery.subscribe(HabEvents.EventsEnum.AddAlertaRegistroCorreo, function (e, oDataGtm) {
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.SwipeFoto, function (e, oDataGtm) {
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.NavSwipeFicha, function (e, oDataGtm) {
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.NavButtonFicha, function (e, oDataGtm) {
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickQuieroContacten, function (e, oDataGtm) {
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickQuieroContactenSimilares, function () {
            var oDataGtm = {
                eventcategory: 'QuieroContacten',
                eventaction: 'Click',
                eventlabel: 'Similares'
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickQuieroContactenSimilaresBtn, function () {
            var oDataGtm = {
                eventcategory: 'QuieroContacten',
                eventaction: 'Click',
                eventlabel: 'SimilaresBtn'
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickQuieroContactenSlickBtn, function () {
            var oDataGtm = {
                eventcategory: 'QuieroContacten',
                eventaction: 'click',
                eventlabel: 'list-gallery'
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickQuieroContactenListado, function () {
            var oDataGtm = {
                eventcategory: 'QuieroContacten',
                eventaction: 'click',
                eventlabel: 'Listado-item'
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickHotmailModal, function (e, sLabel) {
            var oDataGtm = {
                eventcategory: 'HotmailModal',
                eventaction: 'click',
                eventlabel: sLabel
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.CoverClick, function () {
            var oDataGtm = {
                eventcategory: 'ver',
                eventaction: 'fotoCabezeraFicha',
                eventlabel: ''
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ContactarButtonTop, function () {
            var oDataGtm = {
                eventcategory: 'contactar',
                eventaction: 'ficha',
                eventlabel: 'contactar-button-top'
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.VerMasAnunciosEnLaZona, function () {
            var oDataGtm = {
                eventcategory : 'ver',
                eventaction: 'mas_anuncios_zona',
                eventlabel: 'ver'
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickTamanoGaleria, function (e, sAction, sLabel) {
            var oDataGtm = {
                eventcategory: 'ficha',
                eventaction: sAction,
                eventlabel: sLabel
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ErroresForm, function (e, sAction, sLabel) {
            var oDataGtm = {
                eventcategory: 'errores',
                eventaction: sAction,
                eventlabel: sLabel
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickHeader, function (e, sAction, sLabel) {
            var oDataGtm = {
                eventcategory: 'header',
                eventaction : sAction,
                eventlabel: sLabel
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickVerTelefonoFicha, function () {

            var oDataGtm = {
                eventcategory: 'ficha',
                eventaction: 'informacion',
                eventlabel: 'vertelefono'
            };

            __PushTrackEvent(oDataGtm);
        });


        jquery.subscribe(HabEvents.EventsEnum.ClickCompartirFicha, function (e, sLabel) {

            var oDataGtm = {
                eventcategory: 'compartir',
                eventaction: 'social',
                eventlabel: sLabel
            };

            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickContactarFicha, function (e, sLabel) {

            var oDataGtm = {
                eventcategory: 'contactar',
                eventaction: 'ficha',
                eventlabel: sLabel
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.WideMedia, function (e, sAction, sLabel) {
            var oDataGtm = {
                eventcategory: 'ampliar-foto',
                eventaction: sAction,
                eventlabel: sLabel
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.WideMediaThumbnails, function (e, sLabel) {
            var oDataGtm = {
                eventcategory: 'ficha',
                eventaction: 'click',
                eventlabel: sLabel
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.RecibirAlertasSolicitud, function (e, sAction, sLabel) {
        	var oDataGtm = {
        		eventcategory: 'alertas',
        		eventaction: sAction,
        		eventlabel: sLabel
        	};
        	__PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ShowWideMedia, function (e, url) {
            window.GTMEvents.gtmVirtualPageAmpliarFoto.pageName = url;
            dataLayer.push(window.GTMEvents.gtmVirtualPageAmpliarFoto);
        });

        jquery.subscribe(HabEvents.EventsEnum.NotificationLive, function (e, sAction, sLabel) {

            var oDataGtm = {
                eventcategory: 'notificacion-live',
                eventaction: sAction,
                eventlabel: sLabel
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickRemoveAllLive, function () {

            var oDataGtm = {
                eventcategory: 'notificacion-live',
                eventaction: 'click',
                eventlabel: 'remove-all'
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickDescartarAnuncioDesdeLista, function () {
        	var oDataGtm = {
        		eventcategory: 'Anuncios-Descartados',
        		eventaction: 'descartar',
        		eventlabel: 'lista'
        	};
        	__PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickRecuperarAnuncioDesdeLista, function () {
        	var oDataGtm = {
        		eventcategory: 'Anuncios-Descartados',
        		eventaction: 'recuperar',
        		eventlabel: 'lista'
        	};
        	__PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickDescartarAnuncioDesdeFicha, function () {
        	var oDataGtm = {
        		eventcategory: 'Anuncios-Descartados',
        		eventaction: 'descartar',
        		eventlabel: 'ficha'
        	};
        	__PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickRecuperarAnuncioDesdeFicha, function () {
        	var oDataGtm = {
        		eventcategory: 'Anuncios-Descartados',
        		eventaction: 'recuperar',
        		eventlabel: 'ficha'
        	};
        	__PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.RegistroAlertaCriterioListado, function () {
            dataLayer.push(window.GTMEvents.gtmModalAvisameCriterioListado);
        });

        jquery.subscribe(HabEvents.EventsEnum.RegistroAlertaCriterioInmueble, function () {
            dataLayer.push(window.GTMEvents.gtmModalAvisameCriterioInmueble);
        });

        jquery.subscribe(HabEvents.EventsEnum.SocialNetworkCallback, function (e, network, action, target) {

            var oDataGtm = {
                socialNetwork: network,
                socialAction: action,
                socialTarget: target
            };
            __PushSocialEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.UserLogin, function (e, idUsuario) {
            dataLayer.push({ 'UserID': idUsuario });
        });

    

        jquery.subscribe(HabEvents.EventsEnum.ClickBulletNotificacionesLive, function () {
        	var oDataGtm = {
        		eventcategory: 'ClickBulletNotificacionesLive',
        		eventaction: 'click'
        	};
        	__PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickAnuncioLive, function (e, idAnuncio) {
        	var oDataGtm = {
        		eventcategory: 'ClickAnuncioLive',
        		eventaction: 'click',
        		id_anuncio: idAnuncio
        	};
        	__PushTrackEventNotificacionesLive(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.MostrarAnuncioNotificacionLive, function (e, numeroAnuncios) {
        	var oDataGtm = {
        		eventcategory: 'notificacion-live-mobile',
        		eventaction: 'show-anuncio',
        		eventlabel: 'listado',
        		numeroAnuncios: numeroAnuncios
        	};
        	__PushTrackEventNotificacionesLive(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.EliminarTodasNotificacionesLive, function () {
        	var oDataGtm = {
        		eventcategory: 'notificacion-live-mobile',
        		eventaction: 'click-eliminarTodo',
        		eventlabel: 'listado'
        	};
        	__PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.DejarRecibirNotificacionesLive, function () {
        	var oDataGtm = {
        		eventcategory: 'notificacion-live-mobile',
        		eventaction: 'click-dejarrecibir',
        		eventlabel: 'listado'
        	};
        	__PushTrackEvent(oDataGtm);
        });
        

        jquery.subscribe(HabEvents.EventsEnum.MostrarBulletNotificacionesLive, function () {
        	var oDataGtm = {
        		eventcategory: 'notificacion-live-mobile',
        		eventaction: 'show-bullet',
        		eventlabel: 'listado'
        	};
        	__PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickBulletNotificacionesLive, function () {
        	var oDataGtm = {
        		eventcategory: 'notificacion-live-mobile',
        		eventaction: 'click-bullet',
        		eventlabel: 'listado'
        	};
        	__PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickAnuncioLive, function (e, label, idAnuncio) {
        	var oDataGtm = {
        		eventcategory: 'notificacion-live-mobile',
        		eventaction: 'click-anuncio',
        		eventlabel: label,
        		id_anuncio: idAnuncio
        	};
        	__PushTrackEventNotificacionesLive(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickValorarApp, function (e, action, position) {
            var oDataGtm = {
                eventcategory: 'click-valorar-app',
                eventaction: action,
                eventlabel: position,
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickListGallery, function (e, action, position) {
            var oDataGtm = {
                eventcategory: 'click-list-gallery',
                eventaction: action,
                eventlabel: position,
            };
            __PushTrackEvent(oDataGtm);
        });
        jquery.subscribe(HabEvents.EventsEnum.ChangePvpMin, function () {

            var oDataGtm = {
                eventcategory: 'filter',
                eventaction: 'click',
                eventlabel: 'changePvpMin'
            };

            __PushTrackEvent(oDataGtm);
        });
        jquery.subscribe(HabEvents.EventsEnum.ChangePvpMax, function () {

            var oDataGtm = {
                eventcategory: 'filter',
                eventaction: 'click',
                eventlabel: 'changePvpMax'
            };

            __PushTrackEvent(oDataGtm);
        });
        jquery.subscribe(HabEvents.EventsEnum.ContactSpecialWidemedia, function () {
            var oDataGtm = {
                eventcategory: 'ficha',
                eventaction: 'contactar',
                eventlabel: 'widemediaspecial'
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ContactFooterWidemedia, function () {
            var oDataGtm = {
                eventcategory: 'ficha',
                eventaction: 'contactar',
                eventlabel: 'widemediafooter'
            };
            __PushTrackEvent(oDataGtm);
        });


        jquery.subscribe(HabEvents.EventsEnum.ClickSiguienteElementoAgregador, function () {
            var oDataGtm = {
                eventcategory: 'listado',
                eventaction: 'click-aggregator',
                eventlabel: 'next'
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickAnteriorElementoAgregador, function () {
            var oDataGtm = {
                eventcategory: 'listado',
                eventaction: 'click-aggregator',
                eventlabel: 'previous'
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickItemAgregador, function () {
            var oDataGtm = {
                eventcategory: 'listado',
                eventaction: 'click-aggregator',
                eventlabel: 'item'
            };
            __PushTrackEvent(oDataGtm);
        });


        jquery.subscribe(HabEvents.EventsEnum.CambioVistaLista, function (e, category, action) {
            var oDataGtm = {
                eventcategory: category,
                eventaction: action,
                eventlabel: 'ver'
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.RegistrarAlerta, function () {
            var oDataGtm = {
                eventcategory: 'Crear alerta',
                eventaction: 'click',
                eventlabel: 'Call-To-Action'
            };
            __PushTrackEvent(oDataGtm);
        });


        jquery.subscribe(HabEvents.EventsEnum.CambioOrdenacionLista, function (e, tipoLista, tipoOrden) {
            var oDataGtm = {
                eventcategory: tipoLista,
                eventaction: tipoOrden,
                eventlabel: 'ordenar'
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickCalcHipoteca, function (e, label) {
            var oDataGtm = {
                eventcategory: 'calculo-hipoteca',
                eventaction: 'click',
                eventlabel: label
            };
            __PushTrackEvent(oDataGtm);
        });
        
        jquery.subscribe(HabEvents.EventsEnum.ClickFiltroFicha, function () {
            var oDataGtm = {
                eventcategory: 'ficha',
                eventaction: 'click',
                eventlabel: 'filter'
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.PWAEvent, function (e, label) {
            var oDataGtm = {
                eventcategory: 'PWA',
                eventaction: 'interaction',
                eventlabel: label
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.RecuentoIntentosSolicitud, function (e, count) {
            var oDataGtm = {
                eventcategory: 'ficha',
                eventaction: 'attempts',
                eventlabel: count
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.DetailPortal, function () {
            var oDataGtm = {
                eventcategory: 'ficha',
                eventaction: 'view',
                eventlabel: 'portal'
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickLoginFacebookFaldon, function () {
            var oDataGtm = {
                eventcategory: 'faldon',
                eventaction: 'click-facebook',
                eventlabel: 'login-facebook'
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickLoginGoogleFaldon, function () {
            var oDataGtm = {
                eventcategory: 'faldon',
                eventaction: 'click-google',
                eventlabel: 'login-google'
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickLoginCredentialsFaldon, function () {
            var oDataGtm = {
                eventcategory: 'faldon',
                eventaction: 'click-credentials',
                eventlabel: 'login-credentials'
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.ClickCerrarFaldon, function () {
            var oDataGtm = {
                eventcategory: 'faldon',
                eventaction: 'click-cerrar',
                eventlabel: 'cerrar'
            };
            __PushTrackEvent(oDataGtm);
        });

        jquery.subscribe(HabEvents.EventsEnum.RegistrarAlertaOk, function () {
            var oDataGtm = {
                eventcategory: 'alertas',
                eventaction: 'criterio-listado',
                eventlabel: GoogleTagManagerEventsDTO.eventLabel, 
                busquedaoperaciontipo: GoogleTagManagerEventsDTO.busquedaoperaciontipo,
                busquedaprovincia: GoogleTagManagerEventsDTO.busquedaprovincia,
                busquedacomarca: GoogleTagManagerEventsDTO.busquedacomarca,
                busquedapoblacion: GoogleTagManagerEventsDTO.busquedapoblacion,
                busquedazona: GoogleTagManagerEventsDTO.busquedazona,
                busquedasuperficie: GoogleTagManagerEventsDTO.busquedasuperficie,
                busquedahabitaciones: GoogleTagManagerEventsDTO.busquedahabitaciones,
                busquedaprecio: GoogleTagManagerEventsDTO.busquedaprecio,
                busquedafiltros: GoogleTagManagerEventsDTO.busquedafiltros,
                busquedaordenacion: GoogleTagManagerEventsDTO.busquedaordenacion,
                busquedatipolistado: GoogleTagManagerEventsDTO.busquedatipolistado,
                busquedatipo: GoogleTagManagerEventsDTO.busquedatipo,
                usuarioidusuario: GoogleTagManagerEventsDTO.usuarioidusuario
            };
            __PushTrackEventWhitCustomDimensions(oDataGtm);
        });
    };    

    jquery.subscribe(HabEvents.EventsEnum.ClickPreviewCommentRate, function () {
        var oDataGtm = {
            eventcategory: 'ficha',
            eventaction: 'click',
            eventlabel: 'preview-comment-rate'
        };
        __PushTrackEvent(oDataGtm);
    });

    jquery.subscribe(HabEvents.EventsEnum.LoginPreviewCommentRateOrigin, function () {
        var oDataGtm = {
            eventcategory: 'ficha',
            eventaction: 'login',
            eventlabel: 'from-preview-comment-rate'
        };
        __PushTrackEvent(oDataGtm);
    });

    var __PushTrackEvent = function (oDataGtm) {
        dataLayer.push({
            'eventCategory': oDataGtm.eventcategory,
            'eventAction': oDataGtm.eventaction,
            'eventLabel': oDataGtm.eventlabel,
            'event': 'ga-event'
        });
    };

    var __PushSocialEvent = function (oDataGtm) {
        dataLayer.push({
            'socialNetwork': oDataGtm.socialNetwork,
            'socialAction': oDataGtm.socialAction,
            'socialTarget': oDataGtm.socialTarget,
            'event': 'socialInt'
        });
    };

    var __PushTrackEventNotificacionesLive = function (oDataGtm) {
    	dataLayer.push({ 'eventCategory': oDataGtm.eventcategory, 'eventAction': oDataGtm.eventaction, 'eventLabel': oDataGtm.eventlabel, 'event': 'ga-event', 'id-anuncio': oDataGtm.id_anuncio });
    };

    var __PushTrackEventWhitCustomDimensions = function (oDataGtm) {
        dataLayer.push({
            'eventCategory': oDataGtm.eventcategory,
            'eventAction': oDataGtm.eventaction,
            'eventLabel': oDataGtm.eventlabel,
            'event': 'ga-event',
            'busqueda-operacionTipo': oDataGtm.busquedaoperaciontipo,
            'busqueda-provincia': oDataGtm.busquedaprovincia,
            'busqueda-comarca': oDataGtm.busquedacomarca,
            'busqueda-poblacion': oDataGtm.busquedapoblacion,
            'busqueda-zona': oDataGtm.busquedazona,
            'busqueda-superficie': oDataGtm.busquedasuperficie,
            'busqueda-habitaciones': oDataGtm.busquedahabitaciones,
            'busqueda-precio': oDataGtm.busquedaprecio,
            'busqueda-filtros': oDataGtm.busquedafiltros,
            'busqueda-ordenacion': oDataGtm.busquedaordenacion,
            'busqueda-tipolistado': oDataGtm.busquedatipolistado,
            'busqueda-tipo': oDataGtm.busquedatipo,
            'usuario-idUsuario': (oDataGtm.usuarioidusuario === undefined ? 'undefined' : oDataGtm.usuarioidusuario),         
        });
    };

    jquery.subscribe(HabEvents.EventsEnum.clickSimilarWidemedia, function () {
        var oDataGtm = {
            eventcategory: 'ficha',
            eventaction: 'click',
            eventlabel: 'wideMedia-similares'
        };
        __PushTrackEvent(oDataGtm);
    });
    GTMTrackEvents.prototype = {
        constructor: GTMTrackEvents
    };

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = new GTMTrackEvents();
    }
    else {
        window.GTMTrackEvents = new GTMTrackEvents();
    }

}());