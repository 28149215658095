﻿/* globals sui */

window.__DEBUG__ = false;

export default class Layout {

	constructor()
	{
		this.registerEvents();
		this.initScripts();
		this.deactivateConsole();
	}

	registerEvents() {
		window.sui.vendors.addVendorLoadedEventListener(({vendor}) => {
			vendor === 'google_adsense' && window.gads.startAds();
		});
	}

	initScripts() {
		this.initHotjar();
		window.addHotjar === 'True' && sui.vendors.loadSingleVendorByConsent({
			name: 'hotjar',
			script: 'https://static.hotjar.com/c/hotjar-774043.js?sv=6',
			consents: {
				purposes: [9, 10]
			}
		});
	}

	initHotjar() {
		window.hj=window.hj||function(){(window.hj.q=window.hj.q||[]).push(arguments);};
		window._hjSettings={hjid:774043,hjsv:6};
	}

	deactivateConsole() {
		window.__reactivateConsole = function () {
			/*jshint -W020 */
			console = window.__console;
		};

		window.__deactivateConsole = function () {
			window.__console = Object.assign({}, console);
			console.log = console.error = console.info = console.debug = console.warn = console.trace = console.dir = console.dirxml = console.group = console.groupEnd = console.time = console.timeEnd = console.assert = console.profile = function () { };
		};

		if (window.__DEBUG__ === false) {
			if (typeof (console) === 'undefined') {
				/*jshint -W020 */
				console = {};
			}
		}
	
		window.__deactivateConsole();
	}

}

/* jshint unused: false */
const layoutInstance = new Layout();