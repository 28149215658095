﻿var jquery = require('jquery');
var HabEvents = require("../Global/habitaclia-events");
var UIKeyCodes = require("../Global/keycodes");

(function () {
    "use strict";

    //Constructor
    var WindowModal = function () {
        this.parentWindowModel;
        this.onReadyFunction = function () { };
        this.onDestroyFunction = function () { };
        this.options;
        this.lastScroll = 0;
        this.modalContainer = jquery('#js-modal-container');
        this.deleteDiv = false;
    };

    //Public Prototype 
    WindowModal.prototype = {
        constructor: WindowModal,

        CreateWindowModal: function (options) {
            this.options = options;

            if (jquery("#" + this.options.idSelectorString).length === 0) {
                var modalDiv = document.createElement('div');
                modalDiv.id = this.options.idSelectorString;
                modalDiv.className = 'habDialog';
                this.modalContainer.append(modalDiv);
                this.deleteDiv = true;
            }
            
            if (typeof (this.options.useDefaultClose) === "undefined") {
                this.options.useDefaultClose = true;
            }
            this.parentWindowModel = window.document.getElementById(this.options.idSelectorString);
            this.parentWindowModel.innerHTML = _getWindowModalTemplate.call(this);
            jquery("#" + this.options.idSelectorString).addClass('modal-active');

            var oScroll = _getScroll();
            
            _applyWindowModalProperties.call(this, oScroll);

            _configureEvents.call(this);

            this.onReadyFunction.call(this);
            if (typeof (this.options.focusOn) !== "undefined") {
                var oElem = document.getElementById(this.options.focusOn);
                oElem.focus();
            }
            jquery.publish(HabEvents.EventsEnum.CreatedWindowModal);
        },
        OnReady: function (functionToCall) {
            this.onReadyFunction = functionToCall;
        },
        OnDestroy: function (functionToCall) {
            this.onDestroyFunction = functionToCall;
        },
        CloseWindowModal: function () {
            jquery("#" + this.options.idSelectorString).removeClass('modal-active');
            jquery("#" + this.options.idSelectorString + " #wmPosition").off("click");
            jquery("#" + this.options.idSelectorString + " #wmContent").off("click");
            jquery("#" + this.options.idSelectorString + " #wmContent").off("keydown");
            if (this.deleteDiv) {
                jquery("#" + this.options.idSelectorString).remove();
            } else {
                var node = this.parentWindowModel;
                while (node.hasChildNodes()) {
                    node.removeChild(node.firstChild);
                }
            }
            
            _unblockScrollMainWindow.call(this);
            jquery("body").off("focus");
            this.onDestroyFunction.call(this);

            if (this.options.useDefaultClose) {
                jquery.unsubscribe(HabEvents.EventsEnum.CloseWindowModal);
            }
            
        }
    };

    //Private methods
    var _blockScrollMainWindow = function () {
        document.getElementsByTagName("body")[0].className = "wmLockScrollMainWindow";
    };
    var _unblockScrollMainWindow = function () {
        document.getElementsByTagName("body")[0].className = "wmunLockScrollMainWindow";
        jquery('body').css('top', '');
        window.scrollTo(0, this.lastScroll);
    };
    var _getWindowModalTemplate = function () {
        var sTemplate = "";
        var sStyleContent = "";
        var sStyleBody = "";

        //props Modal
        if (typeof (this.options.modalHeight) !== "undefined" && this.options.modalHeight !== null) {
            sStyleContent += "height:" + this.options.modalHeight + ";";
        }
        if (typeof (this.options.modalTop) !== "undefined" && this.options.modalTop !== null) {
            sStyleContent += "top:" + this.options.modalTop;
        }
        if (typeof (this.options.modalWidth) !== "undefined" && this.options.modalWidth !== null) {
            if (sStyleContent.length > 0) {
                sStyleContent += ";width:" + this.options.modalWidth;
            } else {
                sStyleContent += "width:" + this.options.modalWidth;
            }
        }
        if (typeof (this.options.modalOverflowY) !== "undefined" && this.options.modalOverflowY !== null) {
            if (sStyleContent.length > 0) {
                sStyleContent += ";overflow-y:" + this.options.modalOverflowY;
            } else {
                sStyleContent += "overflow-y:" + this.options.modalOverflowY;
            }
        }
        if (sStyleContent.length > 0) {
            sStyleContent = "style='" + sStyleContent + "';";
        }
        if (typeof (this.options.bodyOverflowY) !== "undefined" && this.options.bodyOverflowY !== null) {
            sStyleBody += "overflow-y:" + this.options.bodyOverflowY;
        }
        if (typeof (this.options.bodyHeight) !== "undefined" && this.options.bodyHeight !== null) {
            if (sStyleBody.length > 0) {
                sStyleBody += ";height:" + this.options.bodyHeight;
            } else {
                sStyleBody += "height:" + this.options.bodyHeight;
            }
        }
        if (typeof (this.options.bodyWidth) !== "undefined" && this.options.bodyWidth !== null) {
            if (sStyleContent.length > 0) {
                sStyleBody += ";width:" + this.options.bodyWidth;
            } else {
                sStyleBody += "width:" + this.options.bodyWidth;
            }
        }
        if (sStyleBody.length > 0) {
            sStyleBody = "style='" + sStyleBody + "';";
        }
        sTemplate += '<div id="wmOverlay" class="modal-overlay w-100 h-100"></div>\n';
        sTemplate += '<div id="wmPosition" class="modal-position w-100 h-100">\n';
        sTemplate += '	<div id="wmContent" class="modal-content bg-white" ' + sStyleContent + '>\n';
        sTemplate += '		<div id="wmTitle" class="modal-title">\n';
        sTemplate += '		' + this.options.dataTitle + '\n';
        sTemplate += '		</div>' + "\n";
        sTemplate += '		<div id="wmBody" class="modal-body" ' + sStyleBody + '>\n';
        sTemplate += '		' + this.options.dataBody + '\n';
        sTemplate += '		</div>\n';
        if (typeof (this.options.dataFooter) !== "undefined" && this.options.dataFooter !== null) {
            sTemplate += '		<div id="wmFooter" class="modal-footer">\n';
            sTemplate += '		' + this.options.dataFooter + '\n';
            sTemplate += '		</div>\n';
        }
        sTemplate += '	</div>\n';
        sTemplate += '</div>\n';
        return sTemplate;
    };

    var _configureEvents = function () {
        var me = this;
        jquery("#wmPosition").on("click", function (event) {
            event = event || window.event;
            if (event.target.id === "wmPosition") {
                me.CloseWindowModal();
            }
        });

        jquery("#" + this.options.idSelectorString).on("keydown", function (event) {
            event = event || window.event;
            if (event.keyCode === UIKeyCodes.UIKeyCodesEnum.ESCAPE) {
                me.CloseWindowModal();
            }
        });
        jquery("body").on("focus", function (event) {
            event = event || window.event;
            if (!me.parentWindowModel.contains(event.target)) {
                event.stopPropagation();
                if (typeof (me.options.focusOn) !== "undefined") {
                    window.document.getElementById(me.options.focusOn).focus();
                }
            }
        });
        if (this.options.useDefaultClose) {
            jquery.subscribe(HabEvents.EventsEnum.CloseWindowModal,
                                            function () {
                                                me.CloseWindowModal();
                                            });
        }
    };
    
    var _applyWindowModalProperties = function (oScroll) {
        var oModal = this.parentWindowModel;
        oModal.tabIndex = -1;
        oModal.setAttribute("role", "dialog");
        if (navigator.appName.indexOf("Internet Explorer") === -1) {
            oModal.setAttribute("aria-label", (oModal.getElementsByClassName("titleModal").length > 0 ? oModal.getElementsByClassName("titleModal")[0].innerHTML : "dialog"));
        }
        oModal.focus();
        window.scrollTo(oScroll.x, oScroll.y);
        jquery('body').css('top', -oScroll.y);
        this.lastScroll = oScroll.y;
    };
    var _getScroll = function () {
        var scrOfX = 0, scrOfY = 0;
        if (typeof (window.pageYOffset) === 'number') {
            //Netscape compliant
            scrOfY = window.pageYOffset;
            scrOfX = window.pageXOffset;
        } else if (document.body && (document.body.scrollLeft || document.body.scrollTop)) {
            //DOM compliant
            scrOfY = document.body.scrollTop;
            scrOfX = document.body.scrollLeft;
        } else if (document.documentElement && (document.documentElement.scrollLeft || document.documentElement.scrollTop)) {
            //IE6 standards compliant mode
            scrOfY = document.documentElement.scrollTop;
            scrOfX = document.documentElement.scrollLeft;
        }
        _blockScrollMainWindow.call(this);
        return { x: scrOfX, y: scrOfY };
    };

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = WindowModal;
    }
    else {
        window.WindowModal = WindowModal;
    }

}());