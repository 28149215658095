﻿var jquery = require('jquery');

(function () {
    "use strict";
    //Constructor
    function ProductDetail(dataLayer) {
        this._dataLayer = dataLayer;
        this.itemList = [];
    }

    //Public prototype
    ProductDetail.prototype = {
        constructor: ProductDetail,
        setView: function (oData) {
            if (oData.length > 0) {
                oData = jquery(oData).data();
                this._dataLayer.push({
                    "event": "productDetail",
                    "ecommerce": {
                        "currencyCode": "EUR",
                        "detail": {
                            "products": [{
                                "name": oData.gtmname,
                                "id": oData.gtmid,
                                "brand": oData.gtmbrand,
                                "category": oData.gtmcategory,
                                "variant": oData.gtmvariant
                            }]
                        }
                    },
                    "eventCallback": function () {
                        console.log("GTM productDetail sended");
                    }
                });
            } else {
                console.log("GTM productDetail empty. Not sended");
            }
        }
    };

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = ProductDetail;
    }
    else {
        window.Menu = ProductDetail;
    }

}());