﻿var jquery = require('jquery');

(function () {
    "use strict";
    //Constructor
    function Products(dataLayer) {
        this._dataLayer = dataLayer;
        this.itemList = [];
    }

    //Public prototype
    Products.prototype = {
        constructor: Products,
        click: function (oData, callbackFunction) {
            try {
                this._dataLayer.push({
                    'event': 'listProductClick',
                    'ecommerce': {
                        'currencyCode': 'EUR',
                        'click': {
                            'actionField': { 'list': oData.list ? oData.list : oData.gtmlist },
                            'products': [{
                                'name': oData.name ? oData.name : oData.gtmname,
                                'id': oData.id ? oData.id : oData.gtmid,
                                'brand': oData.brand ? oData.brand : oData.gtmbrand,
                                'price': oData.price ? oData.price : oData.gtmprice,
                                'category': oData.category ? oData.category : oData.gtmcategory,
                                'variant': oData.variant ? oData.variant : oData.gtmvariant,
                                'position': oData.position ? oData.position:  oData.gtmposition
                            }]
                        }
                    }, 'eventCallback': function () { callbackFunction(oData); }
                });
            } catch (err) {
                callbackFunction(oData);
            }
        },
        setView: function (oDataList) {
            var oItem, oDataListItem;

            for (var i = 0; i < oDataList.length; i++) {
                oDataListItem = jquery(oDataList[i]).data();
                oItem = { "name": oDataListItem.gtmname, "id": oDataListItem.gtmid, "brand": oDataListItem.gtmbrand, "price": oDataListItem.gtmprice, "category": oDataListItem.gtmcategory, "variant": oDataListItem.gtmvariant, "list": oDataListItem.gtmlist, "position": oDataListItem.gtmposition };
                this.itemList.push(oItem);
            }
            if (this.itemList.length > 0) {
                this._dataLayer.push({ "event": "listProductsView", "ecommerce": { "currencyCode": "EUR", "impressions": this.itemList }, "eventCallback": console.log("GTM " + this.itemList.length + " products sended") });
            }
        },

        sendListProductView: function (listProductView) {
            if (listProductView.length > 0) {
                this._dataLayer.push({ "event": "listProductsView", "ecommerce": { "currencyCode": "EUR", "impressions": listProductView } });
            }
        }
    };

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = Products;
    }
    else {
        window.Menu = Products;
    }

}());