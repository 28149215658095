﻿var jquery = require('jquery');

(function () {
    "use strict";
    //Constructor
    function AddToCart(dataLayer) {
        this._dataLayer = dataLayer;
    }

    //Public prototype
    AddToCart.prototype = {
        constructor: AddToCart,
        add: function (oData) {
            if (oData.length > 0) {
                oData = jquery(oData).data();
                this._dataLayer.push({
                    "event": "addToCart",
                    "ecommerce": {
                        "currencyCode": "EUR",
                        "add": {
                            "products": [{
                                "name": oData.gtmname,
                                "id": oData.gtmid,
                                "brand": oData.gtmbrand,
                                "price": oData.gtmprice,
                                "category": oData.gtmcategory,
                                "variant": oData.gtmvariant,
                                "quantity": 1
                            }]
                        }
                    }, 'eventCallback': function () { console.log("GTM product added to cart"); }
                });
            } else {
                console.log("GTM product NOT added to cart");
            }
        },
        addWithData: function (data) {
            if (data) {
                this._dataLayer.push({
                    "event": "addToCart",
                    "ecommerce": {
                        "currencyCode": "EUR",
                        "add": {
                            "products": [{
                                "name": data.name,
                                "id": data.id,
                                "brand": data.brand,
                                "price": data.price,
                                "category": data.category,
                                "variant": data.variant,
                                "quantity": 1
                            }]
                        }
                    }, 'eventCallback': function () { console.log("GTM product added to cart"); }
                });
            }
        }
    };

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = AddToCart;
    }
    else {
        window.Menu = AddToCart;
    }

}());