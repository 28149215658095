﻿var jquery = require('jquery');

(function () {
    "use strict";
    //Constructor
    function Purchase(dataLayer) {
        this._dataLayer = dataLayer;
        this.itemList = [];
        this.sended = false;
    }

    //Public prototype
    Purchase.prototype = {
        constructor: Purchase,
        setView: function (oData) {
            if (oData.length > 0) {
                oData = jquery(oData).data();
                this.sended = true;
                this._dataLayer.push({
                    "event": "purchase",
                    "ecommerce": {
                        "currencyCode": "EUR",
                        "purchase": {
                            "actionField": {
                                "id": oData.gtm_af_id,
                                "affiliation": oData.gtm_af_affiliation,
                                "revenue": oData.gtm_af_revenue,
                                "tax": oData.gtm_af_tax,
                                "coupon": oData.gtm_af_coupon
                            },
                            "products": [{
                                "name": oData.gtm_prod_name,
                                "id": oData.gtm_prod_id,
                                "price": oData.gtm_prod_price,
                                "brand": oData.gtm_prod_brand,
                                "category": oData.gtm_prod_category,
                                "variant": oData.gtm_prod_variant,
                                "quantity": 1,
                                "coupon": oData.gtm_prod_coupon
                            }]
                        }
                    },
                    "eventCallback": function () {
                        console.log("GTM purchase sended");
                    }
                });
                this.sended = true;

            } else {
                console.log("GTM purchase empty. Not sended");
            }
        }
    };

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = Purchase;
    }
    else {
        window.Menu = Purchase;
    }

}());