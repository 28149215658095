﻿var jquery = require('jquery');

(function () {
    "use strict";
    //Constructor
    function CheckOut(dataLayer) {
        this._dataLayer = dataLayer;
    }

    //Public prototype
    CheckOut.prototype = {
        constructor: CheckOut,
        setFocus: function (oData) {
            if (oData.length > 0) {
                oData = jquery(oData).data();
                this._dataLayer.push({
                    'event': 'checkout',
                    'ecommerce': {
                        'currencyCode': 'EUR',
                        'checkout': {
                            'actionField': { 'step': 1, 'option': '' },
                            'products': [{
                                'id': oData.gtmid,
                                'name': oData.gtmname,
                                'price': oData.gtmprice,
                                'category': oData.gtmcategory,
                                'brand': oData.gtmbrand,
                                'variant': oData.gtmvariant,
                                'quantity': 1
                            }]
                        }
                    }, 'eventCallback': function () { console.log("GTM product checked out"); }
                });
            } else {
                console.log("GTM product NOT checked Out");
            }
        }
    };

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = CheckOut;
    }
    else {
        window.Menu = CheckOut;
    }

}());