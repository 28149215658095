﻿/* globals  _gaq */
var jquery = require('jquery');
var HabEvents = require("../../Global/habitaclia-events");


(function () {
    "use strict";

    var GoogleAnalyticsEvents = function () {
        __RegisterGoogleAnalyticsEvents();
    };

    var __RegisterGoogleAnalyticsEvents = function () {

        //Eventos Ficha
        jquery.subscribe(HabEvents.EventsEnum.IrAFormularioContacto, function () {
            __PushTrackEvent('Informacion', 'ficha', 'contactarficha');
        });
        jquery.subscribe(HabEvents.EventsEnum.ClickAvisameSiBajaFicha, function (e, sCategory, sAction, sLabel) {
            __PushTrackEvent(sCategory, sAction, sLabel);
        });
        jquery.subscribe(HabEvents.EventsEnum.ClickCrearAlertaDesdeFicha, function (e, sCategory, sAction, sLabel) {
            __PushTrackEvent(sCategory, sAction, sLabel);
        });
        jquery.subscribe(HabEvents.EventsEnum.ClickFlechaAnteriorFicha, function (e, sCategory, sAction, sLabel) {
            __PushTrackEvent(sCategory, sAction, sLabel);
        });
        jquery.subscribe(HabEvents.EventsEnum.ClickFlechaSiguienteFicha, function (e, sCategory, sAction, sLabel) {
            __PushTrackEvent(sCategory, sAction, sLabel);
        });
        jquery.subscribe(HabEvents.EventsEnum.ClickVolverListadoFicha, function (e, sCategory, sAction, sLabel) {
            __PushTrackEvent(sCategory, sAction, sLabel);
        });
        jquery.subscribe(HabEvents.EventsEnum.ClickInmuebleInteresanteFicha, function (e, sCategory, sAction, sLabel) {
            __PushTrackEvent(sCategory, sAction, sLabel);
        });
        jquery.subscribe(HabEvents.EventsEnum.ClickCompartirFichaItem, function (e, sCategory, sAction, sLabel) {
            __PushTrackEvent(sCategory, sAction, sLabel);
        });
        jquery.subscribe(HabEvents.EventsEnum.ClickMasCaracteristicasFicha, function (e, sCategory, sAction, sLabel) {
            __PushTrackEvent(sCategory, sAction, sLabel);
        });
        
        jquery.subscribe(HabEvents.EventsEnum.ClickContactarFicha, function (e, sLabel) {
            __PushTrackEvent('contactar', 'ficha', sLabel);
        });
        jquery.subscribe(HabEvents.EventsEnum.ClickTamanoGaleria, function (e, sAction, sLabel) {
            __PushTrackEvent('ficha', sAction, sLabel);
        });

        jquery.subscribe(HabEvents.EventsEnum.NavegacionFicha, function (e, sAction, sLabel) {
            __PushTrackEvent('Navegacion', sAction, sLabel);
        });
        
        jquery.subscribe(HabEvents.EventsEnum.VerFichaFotocasa, function (e, sAction, sLabel) {
        	__PushTrackEvent('Navegacion', sAction, sLabel);
        });
        
        //Eventos Lista
        jquery.subscribe(HabEvents.EventsEnum.AddAlertaListado, function (e, numeroAlertas, sCategory, sAction, sLabel, oDataGtm) {
            /*jshint unused:false*/
            __PushTrackEvent(sCategory, sAction, sLabel);
        });
        jquery.subscribe(HabEvents.EventsEnum.CambioOrdenacionLista, function (e, sCategory, sAction, sLabel) {
            __PushTrackEvent(sCategory, sAction, sLabel);
        });
        jquery.subscribe(HabEvents.EventsEnum.ClickAnuncioPremiumLista, function (e, sCategory, sAction, sLabel) {
            __PushTrackEvent(sCategory, sAction, sLabel);
        });
        jquery.subscribe(HabEvents.EventsEnum.ClickAnuncioOportunidadLista, function (e, sCategory, sAction, sLabel) {
            __PushTrackEvent(sCategory, sAction, sLabel);
        });
        jquery.subscribe(HabEvents.EventsEnum.ClickAnuncioDestacadoLista, function (e, sCategory, sAction, sLabel) {
            __PushTrackEvent(sCategory, sAction, sLabel);
        });
        jquery.subscribe(HabEvents.EventsEnum.ClickAnuncioModuloLiderLista, function (e, sCategory, sAction, sLabel) {
            __PushTrackEvent(sCategory, sAction, sLabel);
        });
        jquery.subscribe(HabEvents.EventsEnum.ClickAnuncioAdyacenciasLista, function (e, sCategory, sAction, sLabel) {
            __PushTrackEvent(sCategory, sAction, sLabel);
        });
        jquery.subscribe(HabEvents.EventsEnum.ClickAnuncioObraNuevaInteresanteLista, function (e, sCategory, sAction, sLabel) {
            __PushTrackEvent(sCategory, sAction, sLabel);
        });
        jquery.subscribe(HabEvents.EventsEnum.ClickMasFiltrosLista, function (e, sCategory, sAction, sLabel) {
            __PushTrackEvent(sCategory, sAction, sLabel);
        });


        //Eventos Shared
        
        jquery.subscribe(HabEvents.EventsEnum.AreaPrivada, function (e, sAction, sLabel) {
            __PushTrackEvent('Areaprivada', sAction, sLabel);
        });
        
    };

    var __PushTrackEvent = function (sCategory, sAction, sLabel) {
        _gaq.push(['_trackEvent', sCategory, sAction, sLabel]);
    };

    GoogleAnalyticsEvents.prototype = {
        constructor: GoogleAnalyticsEvents
    };

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = new GoogleAnalyticsEvents();
    }
    else {
        window.GoogleAnalyticsEvents = new GoogleAnalyticsEvents();
    }

}());